import React from 'react';
import { IonButton, IonContent } from '@ionic/react';

const PaymentStep = ({ onNext }) => {
  return (
    <IonContent className="step-content">
      <h2>Payment</h2>
      <p>Proceed with payment details.</p>

      <IonButton expand="block" onClick={onNext}>
        Next
      </IonButton>
    </IonContent>
  );
};

export default PaymentStep;
