import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { IonModal, IonContent } from '@ionic/react';
import ModifyMenu from './steps/ModifyMenu';
import PaymentStep from './steps/PaymentStep';
import FinalizeFight from './steps/FinalizeFight';
// import '../../styles/fightProcess/TakerFightProcess.scss';

const TakerFightProcess = forwardRef(({ fightData, onClose }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  console.log(fightData);

  useImperativeHandle(ref, () => ({
    present: () => setIsOpen(true),
    dismiss: () => {
      setIsOpen(false);
      setStep(0);
    },
  }));

  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false);
        setStep(0);
        onClose?.();
      }}
      className="taker-fight-process"
      initialBreakpoint={1}
      breakpoints={[0, 1]}
    >
      <IonContent scrollY={false} className="taker-fight-content">
        {step === 0 && <ModifyMenu fightData={fightData} onNext={handleNextStep} />}
        {step === 1 && <PaymentStep onNext={handleNextStep} />}
        {step === 2 && <FinalizeFight onComplete={() => setIsOpen(false)} />}
      </IonContent>
    </IonModal>
  );
});

TakerFightProcess.displayName = 'TakerFightProcess';

export default TakerFightProcess;
