import React, { useContext, useEffect, useState, useRef, memo, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
/* eslint-disable no-unused-vars */
import {
  IonBadge,
  IonButton,
  IonList,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonToast,
  isPlatform,
} from '@ionic/react';
import { Share } from '@capacitor/share';
// import { getGames } from '../../global/request/bet';
import { getGames, getSpecialsLeagues } from '../../global/request/bet';
import TeamOutcomes from '../fight/team/TeamOutcomes';
import '../../styles/sports/GamesList.scss';
import { AuthContext } from '../authentication/AuthContext';
import { GamesListSkeletonLoader } from '../skeletalLoaders/SkeletalLoaders';
import MatchCard from './MatchCard';
import glovesPic from '../../assets/foodfight_gloves.png';
import pizzaPic from '../../assets/foodfight_pizza.png';
import { ProductTourContext } from '../../global/ProductTourContext';
import { getReferalLink } from '../../global/request/user';
import useTeamsUIConfig from '../../global/useTeamsUIConfig';

const timestampDayDiff = timestamp => {
  const gameday = new Date(new Date(timestamp).toDateString());
  const diff = (gameday - new Date()) / 1000 / 60 / 60 / 24;
  if (diff < -2) {
    return `${Math.abs(Math.floor(diff))} days ago`;
  }
  if (diff < -1) {
    return 'Yesterday';
  }
  if (diff < 0) {
    return 'Today';
  }
  if (diff < 1) {
    return 'Tomorrow';
  }
  return `In ${Math.ceil(diff)} days`;
};

const timestampDay = timestamp => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0); // Set hours to midnight
  return date;
};

const initialSports = [
  { name: 'nfl', leagues: [] },
  // { name: 'cfb', leagues: [] },
  { name: 'nhl', leagues: [] },
  { name: 'nba', leagues: [] },
  // { name: 'mlb', leagues: [] },
  { name: 'cbb', leagues: [] },
  {
    name: 'soccer',
    leagues: [
      "champion's league",
      'bundesliga',
      'la liga',
      'premier league',
      'mls',
      'ligue 1',
      'serie a',
      // 'euro championship',
      // 'copa america',
    ],
  },
  { name: 'mma', leagues: [], beta: true },
];

const GamesList = ({ handleClick, searchQuery, handleFightClick, isSportPage = false, setSelectedOutcomeSport }) => {
  const authCtx = useContext(AuthContext);
  const [selectedSport, setSelectedSport] = useState(initialSports[0]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [sportsData, setSportsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leagues, setLeagues] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sports, setSports] = useState(initialSports);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);
  const [selectedDay, setSelectedDay] = useState(initialDate); // Initial state for selected day
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const bearerToken = authCtx.tokens.idToken;
  const referalButtonRef = useContext(ProductTourContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('Referal link copied to clipboard');
  const referralLink = useRef({ link: '', isFetching: false });
  // const referralLinkDisplay = useRef();
  const gamesListRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [reload, specialsTeamsConfig, loadingConfig, errors] = useTeamsUIConfig();
  const skeletonKeys = useRef([...Array(8)].map(() => uuidv4()));

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (selectedSport.name === 'specials') {
          const specialsLeagues = sports.find(sport => sport.name === 'specials')?.leagues || [];
          setLeagues(specialsLeagues);
          const gamesResponse = await getGames(authCtx.tokens.idToken, selectedSport.name, selectedLeague);
          const specialsGames = gamesResponse.games || [];
          setSportsData(specialsGames);
          reload();

          // Determine the first day with games
          const gameDays = gamesResponse.games.map(game => timestampDay(game.timestamp * 1000));
          const uniqueGameDays = [...new Set(gameDays.map(day => day.toISOString()))];
          const firstDayWithGames = uniqueGameDays.length > 0 ? new Date(uniqueGameDays[0]) : null;
          const date = firstDayWithGames || new Date();
          date.setHours(0, 0, 0, 0);
          setSelectedDay(date);
        } else {
          setLeagues(selectedSport.leagues || []);
          const gamesResponse = selectedLeague
            ? await getGames(authCtx.tokens.idToken, selectedLeague)
            : await getGames(authCtx.tokens.idToken, selectedSport.name);
          setSportsData(gamesResponse.games || []);
          // Determine the first day with games
          const gameDays = gamesResponse.games.map(game => timestampDay(game.timestamp * 1000));
          const uniqueGameDays = [...new Set(gameDays.map(day => day.toISOString()))];
          const firstDayWithGames = uniqueGameDays.length > 0 ? new Date(uniqueGameDays[0]) : null;
          const date = firstDayWithGames || new Date();
          date.setHours(0, 0, 0, 0); // Set hours to midnight
          setSelectedDay(date);
        }
      } catch (error) {
        console.error('Error fetching games or leagues:', error);
        setSportsData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [selectedSport, selectedLeague]);

  const handleSportClick = sport => {
    setSelectedSport(sport);
    setSelectedLeague(null);
    setLeagues([]);
  };

  const handleLeagueClick = league => {
    setSelectedLeague(league);
  };

  const handleGameClick = (e, gameId, match) => {
    e.preventDefault();
    handleClick(match);
    if (selectedGameId === gameId) {
      setSelectedGameId(gameId);
    } else {
      setIsDisabled(true);
      setSelectedGameId(gameId);
      setSelectedTeam(match.teams.home.acronym);
    }
  };

  const handleTeamSelect = team => {
    setIsDisabled(true);
    setSelectedTeam(team);
  };

  const handleDayChange = event => {
    const date = new Date(event.detail.value);
    date.setHours(0, 0, 0, 0);
    setSelectedDay(date);
    setIsCalendarVisible(false); // Hide the calendar after date selection
  };

  const setReferralLink = () => {
    referralLink.current.isFetching = true;
    getReferalLink(bearerToken)
      .then(result => {
        referralLink.current = { link: result, isFetching: false };
      })
      .catch(error => {
        referralLink.current.isFetching = false;
        console.error('Failed to fetch referral link:', error);
      });
  };

  // useEffect(() => {
  //   const fetchSpecialsLeagues = async () => {
  //     const specialsLeagues = await getSpecialsLeagues(authCtx.tokens.idToken);
  //     if (Array.isArray(specialsLeagues) && specialsLeagues.length > 0) {
  //       setSports(prev => {
  //         if (!prev.some(sport => sport.name === 'specials')) {
  //           return [
  //             ...prev,
  //             {
  //               name: 'specials',
  //               leagues: specialsLeagues,
  //             },
  //           ];
  //         }
  //         return prev;
  //       });
  //     }
  //   };

  //   fetchSpecialsLeagues();
  //   setReferralLink();
  // }, []);

  // showReferralLink will show the link on screen so the user can manually copy.
  // It should only happen if all other methods fail
  // Commented out to avoid showing it by accident
  // const showReferralLink = () => {
  //   referralLinkDisplay.current.style.display = 'block';
  //   referralLinkDisplay.current.innerText = referralLink.current.link;
  //   setToastMessage('Could not copy to clipboard. Please copy referral link manually');
  //   setShowToast(true);
  // };

  // Use navigator.clipboard to copy link. Call showReferralLink on failure
  const copyReferralToClipboard = () => {
    try {
      navigator.clipboard
        .writeText(referralLink.current.link)
        .then(() => {
          setToastMessage('Referal link copied to clipboard');
          setShowToast(true);
        })
        .catch(error => {
          console.error('Failed to copy referral code to clipboard after fetch:', error);
          // showReferralLink();
        });
    } catch (error) {
      console.error('Failed to use navigator.clipboard', error);
      // showReferralLink();
    }
    // Refresh referral link. Unsure if this is necessary, but the old implemenation
    // got a new link every time the button was pressed, so this mimics that behavior
    setReferralLink();
  };

  // Try showing capacitor Share.share modal. Return false on failure
  const tryShareModal = async () => {
    try {
      const url = referralLink.current.link;
      const canShareResult = await Share.canShare();
      if (canShareResult.value) {
        try {
          await Share.share({ url });
          setReferralLink();
        } catch (error) {
          if (!error.message.toLowerCase().includes('cancel')) {
            throw new Error(`Failed to use share modal: ${error}`);
          }
        }
      } else {
        throw new Error('canShare returned false');
      }
    } catch (error) {
      throw new Error(`Failed to check canShare: ${error}`);
    }
  };

  // If not on a desktop, will try showing a share modal. Otherwise, copy to clipboard directly
  const handleReferralClick = () => {
    if (referralLink.current.isFetching) {
      setToastMessage('Referral link still loading. Try again');
      setShowToast(true);
      return;
    }
    if (isPlatform('desktop')) {
      copyReferralToClipboard();
    } else {
      tryShareModal().catch(error => {
        console.error('Failed to use Share API:', error.message);
        copyReferralToClipboard();
      });
    }
  };

  const normalize = str => (str ? str.toString().toLowerCase().replace(/\s+/g, '') : '');

  const filteredGames = sportsData.filter(match => {
    const team1 = normalize(match.teams.home.name);
    const team2 = normalize(match.teams.away.name);
    const acronym1 = normalize(match.teams.home.acronym);
    const acronym2 = normalize(match.teams.away.acronym);
    const query = normalize(searchQuery);
    const day = timestampDayDiff(match.timestamp * 1000);

    return (
      (team1.includes(query) || team2.includes(query) || acronym1.includes(query) || acronym2.includes(query)) &&
      day === timestampDayDiff(selectedDay.getTime())
    );
  });

  const referCard = (
    <div id="sports-refer">
      <IonButton
        ref={referalButtonRef}
        className="sports-refer-button"
        id="sports-refer-btn"
        expand="block"
        onClick={handleReferralClick}
      >
        <img src={glovesPic} className="sports-refer-image left" alt="" />
        <div className="sports-refer-button__text">
          <p className="title">Refer a Friend</p>
        </div>
        <img src={pizzaPic} className="sports-refer-image right" alt="" />
      </IonButton>
    </div>
  );

  const renderGames = useMemo(() => {
    if (loading) {
      return (
        <IonList>
          {skeletonKeys.current.map(key => (
            <GamesListSkeletonLoader key={key} />
          ))}
        </IonList>
      );
    }

    if (filteredGames.length > 0) {
      return (
        <IonList ref={gamesListRef} className="games">
          {filteredGames.map((match, index) => {
            let timestamp;
            if (match.timestamp) {
              timestamp = match.timestamp * 1000;
            } else {
              console.error('Unexpected data structure for match:', match);
              return null;
            }
            const displayTime = new Date(timestamp).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
            });

            return (
              <React.Fragment key={match.id}>
                <div
                  className="gameItem"
                  onClick={e => handleGameClick(e, match.id, match)}
                  tabIndex={0}
                  role="button"
                  type="button"
                >
                  <MatchCard
                    match={{
                      time: displayTime,
                      homeTeam: {
                        name: match.teams.home.acronym,
                        color: match.teams.home.color,
                      },
                      awayTeam: {
                        name: match.teams.away.acronym,
                        color: match.teams.away.color,
                      },
                    }}
                    isSelected={selectedGameId === match.id}
                    teamSelected={selectedTeam}
                    gameData={match}
                    handleClick={handleClick}
                    onTeamSelect={handleTeamSelect}
                    isDisabled={isDisabled}
                    handleFightClick={handleFightClick}
                    isFight
                  />
                </div>
                {selectedGameId === match.id && (
                  <TeamOutcomes
                    gameId={match.id}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    setIsDisabled={setIsDisabled}
                    isSportPage={isSportPage}
                    setSelectedOutcomeSport={setSelectedOutcomeSport}
                  />
                )}
                {(index + 1) % 5 === 0 && referCard}
              </React.Fragment>
            );
          })}
        </IonList>
      );
    }

    return (
      <div className="no-games-wrapper">
        <div className="no-games">Check back later</div>
      </div>
    );
  }, [loading, filteredGames, selectedGameId, selectedTeam, isDisabled]);
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 4);
  return (
    <div className="games-list">
      <div className="sports-carousel">
        {sports.map(sport => (
          <div key={sport.name} className="sport-container">
            <div className="sport-btn-container">
              <IonButton
                className={`sport-btn ${sport === selectedSport ? 'active' : ''}`}
                onClick={() => handleSportClick(sport)}
                shape="round"
              >
                <p>{sport.name.toUpperCase()}</p>
              </IonButton>
              {sport.beta && <IonBadge className="beta-badge">Beta</IonBadge>}
            </div>
          </div>
        ))}
      </div>
      <div className="sports-carousel">
        {selectedSport.name !== 'specials'
          ? selectedSport.leagues.length > 0 && (
              <div className="sports-carousel">
                {selectedSport.leagues.map(league => (
                  <div key={league} className="sport-btn-container">
                    <IonButton
                      onClick={() => handleLeagueClick(league)}
                      className={`sport-btn ${league === selectedLeague ? 'active' : ''}`}
                      shape="round"
                    >
                      {league.toUpperCase()}
                    </IonButton>
                  </div>
                ))}
              </div>
            )
          : leagues.length > 0 && (
              <div className="sports-carousel">
                {leagues.map(league => (
                  <div key={league} className="sport-btn-container">
                    <IonButton
                      onClick={() => handleLeagueClick(league)}
                      className={`sport-btn ${league === selectedLeague ? 'active' : ''}`}
                      shape="round"
                    >
                      {league.toUpperCase()}
                    </IonButton>
                  </div>
                ))}
              </div>
            )}
      </div>
      <div className="day-filter">
        <IonButton onClick={() => setIsCalendarVisible(!isCalendarVisible)} className="calendar-button">
          {selectedDay.toDateString() === new Date().toDateString()
            ? 'Today'
            : selectedDay.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
        </IonButton>
        {isCalendarVisible && (
          <IonDatetime
            className="calendar"
            displayFormat="MM/DD/YYYY"
            presentation="date"
            min={minDate.toISOString()}
            max={maxDate.toISOString()}
            value={selectedDay.toISOString()}
            onIonChange={handleDayChange}
          />
        )}
      </div>
      {/* <p ref={referralLinkDisplay} className="referral-link-display" /> */}
      {renderGames}
      <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={2000} />
    </div>
  );
};

export default memo(GamesList);
