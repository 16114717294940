import React, { useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import useGoogleMapsLoader from './useGoogleMapsLoader';

const GoogleAutocomplete = ({ placeholder, onSelect }) => {
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);
  const { isLoaded } = useGoogleMapsLoader();

  const onLoad = ref => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) return;
    onSelect(places[0]);
  };

  return isLoaded ? (
    <StandaloneSearchBox
      onLoad={onLoad}
      onPlacesChanged={onPlacesChanged}
      options={{ types: ['(regions)'], componentRestrictions: { country: 'us' } }}
    >
      <input
        ref={inputRef}
        type="text"
        className="autocomplete-input"
        placeholder={placeholder}
        style={{ width: '100%', padding: '16px 18px', fontSize: '22px', textAlign: 'center' }}
      />
    </StandaloneSearchBox>
  ) : (
    <div>Loading...</div>
  );
};

export default GoogleAutocomplete;
