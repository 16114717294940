import React from 'react';
import { IonButton, IonContent } from '@ionic/react';

const ModifyMenu = ({ onNext }) => {
  return (
    <IonContent className="step-content">
      <h2>Modify Menu</h2>
      <p>Select and modify your fight menu items.</p>

      <IonButton expand="block" onClick={onNext}>
        Next
      </IonButton>
    </IonContent>
  );
};

export default ModifyMenu;
