import React, { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';

import GoogleAutocomplete from '../../googleMapsApi/GoogleAutocomplete';
import { parseFormattedAddress } from '../../global/util';

import '../../styles/addressFields/AddressFields.scss';

const AddressFields = ({
  addressDetails,
  setAddressDetails,
  setIsFormValid,
  showAutocompleteDefault = true,
  className = '',
  pastAddresses,
}) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const requiredAddressFields = ['streetAddress', 'city', 'state', 'zip'];

  const [showAutocomplete, setShowAutocomplete] = useState(showAutocompleteDefault);
  const [showPastAddresses, setShowPastAddresses] = useState(false);

  const trySetIsFormValid = val => {
    if (typeof setIsFormValid === 'function') {
      setIsFormValid(val);
    }
  };

  const handleInputChange = (field, value) => {
    let cleanedValue = value;
    if (field === 'zip') {
      cleanedValue = value.replace(/\D/g, '').slice(0, 5);
    }

    const updatedDetails = { ...addressDetails, [field]: cleanedValue };
    setAddressDetails(updatedDetails);
  };

  // GOOGLE AUTOCOMPLETE

  const handlePlaceSelect = place => {
    const addressComponents = place.address_components.reduce((acc, { types, long_name, short_name }) => {
      if (types.includes('street_number')) acc.streetNumber = long_name;
      if (types.includes('route')) acc.street = long_name;
      if (types.includes('locality') || (types.includes('sublocality') && !acc.city)) acc.city = long_name;
      if (types.includes('administrative_area_level_1')) acc.state = short_name;
      if (types.includes('postal_code')) acc.zip = long_name;
      return acc;
    }, {});

    const addr = {
      ...addressDetails,
      streetAddress: `${addressComponents.streetNumber || ''} ${addressComponents.street || ''}`.trim(),
      city: addressComponents.city || '',
      state: addressComponents.state || '',
      zip: addressComponents.zip || '',
    };
    setAddressDetails(addr);
    setShowAutocomplete(false);
  };

  /// END OF GOOGLE AUTOCOMPLETE

  const handleAddressClick = address => {
    const addrDetails = parseFormattedAddress(address);
    if (!addrDetails.invalid) {
      setAddressDetails(addrDetails);
      setShowAutocomplete(false);
    }
  };

  useEffect(() => {
    let addr = {};
    if (typeof addressDetails === 'object') {
      addr = { ...addressDetails };
    }
    trySetIsFormValid(requiredAddressFields.every(key => addr[key]?.trim() !== ''));
  }, [addressDetails]);

  return (
    <div className={`inputs-wrapper ${className}`}>
      {showAutocomplete ? (
        <GoogleAutocomplete
          apiKey={googleApiKey}
          placeholder="&#x1F50D;  Where's the fight?"
          onSelect={handlePlaceSelect}
          className="autocomplete-input-form"
          style={{ width: '100%' }}
        />
      ) : (
        <div className="toggle-autocomplete-text-container show-autocomplete-text-container">
          <button type="button" onClick={() => setShowAutocomplete(true)} className="toggle-autocomplete-text">
            Search with Google Autocomplete
          </button>
        </div>
      )}
      {!showAutocomplete ? (
        <>
          <span className="manual-label-title">Address*</span>
          <input
            type="text"
            className="address-field-input street-address-field-input"
            value={addressDetails.streetAddress}
            placeholder="Enter Address"
            onChange={e => handleInputChange('streetAddress', e.target.value)}
          />
          <span className="manual-label-title">Address</span>
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.apt}
            placeholder="Enter Address (Optional)"
            onChange={e => handleInputChange('apt', e.target.value)}
          />
          <span className="manual-label-title">City*</span>
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.city}
            placeholder="Enter City"
            onChange={e => handleInputChange('city', e.target.value)}
          />
          <div className="manual-state-zip">
            <div className="manual-state">
              <span className="manual-label-title">State*</span>
              <input
                type="text"
                className="address-field-input state-input"
                value={addressDetails.state}
                placeholder="State"
                onChange={e => handleInputChange('state', e.target.value)}
              />
            </div>
            <div className="manual-zip">
              <span className="manual-label-title">Zipcode*</span>
              <input
                type="number"
                className="address-field-input zip-input"
                value={addressDetails.zip}
                placeholder="ZIP Code"
                onChange={e => handleInputChange('zip', e.target.value)}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="toggle-autocomplete-text-container">
          <button type="button" onClick={() => setShowAutocomplete(false)} className="toggle-autocomplete-text">
            Manually Enter Address Fields
          </button>
        </div>
      )}
      {pastAddresses && pastAddresses.length > 0 && (
        <button
          type="button"
          onClick={() => setShowPastAddresses(prev => !prev)}
          className="toggle-autocomplete-text-container toggle-autocomplete-text toggle-past-addr-text"
        >
          {showPastAddresses ? 'Hide Past Addresses' : 'Choose a Past Address'}
        </button>
      )}
      {showPastAddresses &&
        pastAddresses.map(address => (
          <IonButton className="past-address ion-text-wrap" onClick={() => handleAddressClick(address)} key={address}>
            {address}
          </IonButton>
        ))}
    </div>
  );
};

export default AddressFields;
