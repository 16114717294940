import React from 'react';
import { IonButton, IonContent } from '@ionic/react';

const FinalizeFight = ({ onComplete }) => {
  return (
    <IonContent className="step-content">
      <h2>Finalize Fight</h2>
      <p>Confirm and submit your changes.</p>

      <IonButton expand="block" onClick={onComplete}>
        Finalize
      </IonButton>
    </IonContent>
  );
};

export default FinalizeFight;
