import React, { useContext, useRef, useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { IonButton, IonPage, IonContent, useIonRouter, IonLabel } from '@ionic/react';
import { NativeBiometric, BiometryType } from 'capacitor-native-biometric';
import EntryField from './EntryField';
import '../../styles/authentication/SignIn.scss';
import dancingPizza from '../../assets/foodfight_pizza.png';
import longLogoText from '../../assets/foodfight_red_primary_02.png';
import { AuthDispatchContext } from './AuthContext';
import { signin } from '../../global/request/auth';
import MessageOverlay from '../fight/MessageOverlay';
import usePushNotifcations from '../notifications/PushNotifications';
import { login as amityLogin } from '../../services/amityClient';
import { verifyLogin } from '../../global/request/user';

function SignIn() {
  const router = useIonRouter();
  const authDispatchCtx = useContext(AuthDispatchContext);
  const msgRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authErrMsg, setAuthErrMsg] = useState('');
  const [amityLoginStatus, setAmityLoginStatus] = useState(null);
  const [biometricChecked, setBiometricChecked] = useState(false);

  const handleSignIn = async () => {
    try {
      // Authenticate with our app
      const result = await signin({
        email,
        password,
      });

      console.log('signin result:', result);

      // userInfo
      const userId = result.idData.sub;
      const displayName = result.idData.email;

      console.log('userId:', userId);
      console.log('displayName:', displayName);

      authDispatchCtx.dispatch({
        action: 'storeAuthResult',
        authResult: result,
      });

      const isConnected = await amityLogin(userId, displayName);

      if (isConnected) {
        setAmityLoginStatus('Login to Amity successful');
        if (Capacitor.isNativePlatform()) {
          NativeBiometric.setCredentials({
            username: result.idData.email,
            password: result.idData.password,
            server: 'getfoodfight.link',
          }).then();
          console.log('Credentials saved to Biometric storage.');
        }
        await Preferences.set({ key: 'showVenueModal', value: 'true' });
        router.push('/app/home');
      } else {
        setAmityLoginStatus('Login to Amity failed');
      }

      try {
        await verifyLogin(result.tokens.idToken);
        console.log('Login verification successful');
      } catch (error) {
        console.error('Error verifying login:', error);
      }
    } catch (error) {
      setAuthErrMsg(error.message);
      msgRef.current.present();
      console.error('handleSignIn error:', error);
    }
  };

  const checkBiometricAndRetrieveCredentials = async () => {
    try {
      if (biometricChecked) return;

      const result = await NativeBiometric.isAvailable();
      if (!result.isAvailable) {
        console.log('Biometric authentication is not available.');
        return;
      }

      const isFaceID = result.biometryType === BiometryType.FACE_ID;
      console.log('Using Face ID:', isFaceID);

      const verified = await NativeBiometric.verifyIdentity({
        reason: 'For easy log in',
        title: 'Log in',
        subtitle: 'Authenticate to access your account',
        description: 'Use Face ID to quickly log in',
      })
        .then(() => true)
        .catch(() => false);

      if (!verified) {
        console.log('Face ID Authentication Failed.');
        return;
      }

      const credentials = await NativeBiometric.getCredentials({
        server: 'getfoodfight.link',
      });

      if (credentials) {
        setEmail(credentials.username);
        setPassword(credentials.password);
        setBiometricChecked(true);
      }
    } catch (error) {
      console.error('Error with Face ID authentication or retrieving credentials:', error);
    }
  };

  useEffect(() => {
    if (amityLoginStatus) {
      console.log(amityLoginStatus);
    }
  }, [amityLoginStatus]);

  useEffect(() => {
    if (email && password && biometricChecked) {
      handleSignIn();
    }
  }, [email, password, biometricChecked]);

  usePushNotifcations();

  return (
    <IonPage>
      <IonContent id="signin-page">
        <div id="signin-content">
          <img className="dancing-pizza" src={dancingPizza} alt="FoodFight Dancing Pizza" />
          <div id="logo-imgs">
            <img id="logo-text" src={longLogoText} alt="FoodFight Alphanumeric" />
          </div>
          <p className="tagline-text">Losers Pay, Winners Eat!</p>
          <form id="entry-options" onSubmit={event => event.preventDefault()}>
            <IonLabel className="login-label">Account</IonLabel>
            <EntryField id="email" placeholder="johndoe@email.com" type="email" setter={setEmail} value={email} />
            <IonLabel className="login-label">Password</IonLabel>
            <EntryField id="password" placeholder="***********" type="password" setter={setPassword} value={password} />
            <div className="forgot-password" role="button" tabIndex={0} onClick={() => router.push('/reset-password')}>
              Forgot Password?
            </div>
            <div className="signin-buttons">
              <IonButton id="signin-btn" expand="block" onClick={handleSignIn}>
                Sign In
              </IonButton>
              <IonButton id="faceid-btn" expand="block" fill="clear" onClick={checkBiometricAndRetrieveCredentials}>
                Face ID
              </IonButton>
            </div>
            <IonButton id="signup-btn" expand="inline-block" fill="outline" onClick={() => router.push('/signup')}>
              Create an Account
            </IonButton>
          </form>
          <div className="term-condition-wrapper">
            <p className="condition-text">By selecting any of these options you agree to</p>
            <div className="term-condition-links">
              FoodFight’s<a href="https://www.getfoodfight.com/">Terms of Service </a>and
              <a href="https://www.getfoodfight.com/"> Privacy Policy</a>
            </div>
          </div>
        </div>
        <MessageOverlay blur autoExit lifespan={2.5} msg={authErrMsg} ref={msgRef} />
      </IonContent>
    </IonPage>
  );
}

export default SignIn;
