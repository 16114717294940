import React, { useState, useEffect } from 'react';
import { IonButton, IonContent, IonHeader, IonLabel, IonPage, useIonRouter, IonIcon } from '@ionic/react';
import '../../styles/authentication/ResetPassword.scss';
import { chevronBackOutline } from 'ionicons/icons';
import SixDigitInput from './SixDigitInput';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLongEnough, setIsLongEnough] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [step, setStep] = useState('email');
  const [message, setMessage] = useState('');
  const [errorMsg, setError] = useState(false);

  const router = useIonRouter();

  const resetForm = () => {
    setEmail('');
    setCode('');
    setNewPassword('');
    setStep('email');
    setMessage('');
  };

  const navigateToSignIn = () => {
    router.push('/signin');
    resetForm();
  };

  const apiUrl = 'https://vhx3rygae9.execute-api.us-east-1.amazonaws.com/dev/auth';

  const postToApi = async body => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Information you provided is incorrect. Try again.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error during API call', error);
      throw error;
    }
  };

  const handleResetPassword = async e => {
    e.preventDefault();
    setMessage('');
    if (step === 'reset' && newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      if (step === 'email') {
        const response = await postToApi({ action: 'forgot_password', email });
        setPhoneNumber(response.CodeDeliveryDetails.Destination);
        setMessage('A reset code has been sent to the phone number associated with your account.');
        setStep('reset');
        setError(false);
      } else if (step === 'reset') {
        await postToApi({ action: 'confirm_forgot_password', email, code, password: newPassword });
        setMessage('Your password has been reset successfully.');
        setStep('confirm');
        setError(true);
      }
    } catch (error) {
      setMessage(error.message);
      setError(true);
    }
  };

  const handleCodeInput = processedCode => {
    setCode(processedCode);
  };

  useEffect(() => {
    setIsLongEnough(newPassword.length >= 8);
    setHasNumber(/\d/.test(newPassword));
    setHasLetter(/[A-Z]/.test(newPassword));
    setPasswordsMatch(newPassword && confirmPassword && newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  const getClassIfTrue = condition => (condition ? 'item green' : 'item');

  return (
    <IonPage className="ion-page">
      <IonContent id="reset-page">
        <div className="reset-password-content">
          <div className="logo-icon" />
          <IonHeader className="reset-password-header">Reset your Password</IonHeader>
          <div className="back-to-homepage" role="button" tabIndex={0} onClick={navigateToSignIn}>
            <IonIcon slot="icon-only" icon={chevronBackOutline} className="signup-back-icon" />
          </div>
          <form className="form-content" onSubmit={handleResetPassword}>
            {step === 'email' && (
              <>
                <IonLabel className="label">Email</IonLabel>
                <input
                  className="data-input"
                  type="email"
                  placeholder="johndoe@email.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <IonButton className="next-button" type="submit">
                  Next
                </IonButton>
              </>
            )}

            {step === 'reset' && (
              <>
                <p className="verification-remark">
                  We have sent the code to <br /> {phoneNumber}
                </p>
                <SixDigitInput onCodeChange={handleCodeInput} />
                {/* <IonLabel className="label">Code</IonLabel>
                <input
                  className="data-input"
                  type="text"
                  placeholder="Enter the code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                /> */}
                <IonLabel className="label">Password</IonLabel>
                <input
                  className="data-input"
                  type="password"
                  placeholder="**********"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <IonLabel className="label">Confirm password</IonLabel>
                <input
                  className="data-input"
                  type="password"
                  placeholder="**********"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <div className="password-req">
                  <ul>
                    <li id="password-capital" className={getClassIfTrue(hasLetter)}>
                      Password must have at least one capital letter
                    </li>
                    <li id="password-length" className={getClassIfTrue(isLongEnough)}>
                      Password must be at least 8 characters long
                    </li>
                    <li id="password-number" className={getClassIfTrue(hasNumber)}>
                      Password must contain at least one number
                    </li>
                    <li id="password-match" className={getClassIfTrue(passwordsMatch)}>
                      Passwords must match
                    </li>
                  </ul>
                </div>
                <IonButton className="next-button" type="submit">
                  Confirm
                </IonButton>
              </>
            )}

            {message && errorMsg && step === 'confirm' && <p className="reset-msg"> {message}</p>}
          </form>
          {message && errorMsg && step !== 'confirm' && <p className="reset-msg">{message}</p>}
        </div>
        <div className="question-wrapper">
          <p className="condition-text">Still got an issue?</p>
          <div className="term-condition-links">
            <a href="https://www.getfoodfight.com/">Contact Us </a>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
