/** @module global/request/sports */
// import { restaurant } from 'ionicons/icons';
// import { add } from 'ionicons/icons';
import RequestUtility from './request';

import nflData from '../../data/Sports/NFL.json';
import ncaafData from '../../data/Sports/NCAAF.json';
import nbaData from '../../data/Sports/NBA.json';
import nhlData from '../../data/Sports/NHL.json';
import cbbData from '../../data/Sports/CBB.json';
import mlbData from '../../data/Sports/MLB.json';
import soccerData from '../../data/Sports/SOCCER.json';
import specialsData from '../../data/Sports/SPECIALS.json';

const SERVICE_URL =
  window.location.hostname === 'www.getfoodfight.link'
    ? 'https://d2if4tc6g5.execute-api.us-east-1.amazonaws.com/prod'
    : 'https://v5j4xszs7i.execute-api.us-east-1.amazonaws.com/prod';
const util = new RequestUtility(SERVICE_URL);

const DEFAULT_COLOR = '#878787';
const SOCCER_LEAGUES = [2, 4, 9, 39, 61, 135, 78, 140, 253];
const CARJITSU_LEFT_COLOR = '#0000FF';
const CARJITSU_RIGHT_COLOR = '#FF0000';
const MMA_LEFT_COLOR = '#FF3737';
const MMA_RIGHT_COLOR = '#3737FF';

const getSportLeague = teamName => {
  let sport;
  if (nhlData.find(team => team.name === teamName)) {
    sport = 'NHL';
  } else if (mlbData.find(team => team.name === teamName)) {
    sport = 'MLB';
  } else if (soccerData.find(team => team.name === teamName)) {
    sport = 'Soccer';
  } else if (cbbData.find(team => team.name === teamName)) {
    sport = 'CBB';
  } else if (ncaafData.find(team => team.name === teamName)) {
    sport = 'NCAAF';
  } else if (nbaData.find(team => team.name === teamName)) {
    sport = 'NBA';
  } else if (nflData.find(team => team.acronym === teamName)) {
    sport = 'NFL';
  } else if (specialsData.find(team => team.name === teamName)) {
    sport = 'Specials';
  } else {
    sport = 'Other';
  }
  return sport;
};

const appendMatchColors = match => {
  const homeColor =
    nflData.find(team => team.acronym === match.makerOutcomeTeam)?.color ??
    ncaafData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    nbaData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    cbbData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    nhlData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    mlbData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    soccerData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    specialsData.find(team => team.name === match.makerOutcomeTeam)?.color ??
    CARJITSU_LEFT_COLOR;

  const awayColor =
    nflData.find(team => team.acronym === match.takerOutcomeTeam)?.color ??
    ncaafData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    nbaData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    nhlData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    mlbData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    soccerData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    cbbData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    specialsData.find(team => team.name === match.takerOutcomeTeam)?.color ??
    CARJITSU_RIGHT_COLOR;

  const homeAcronym =
    nflData.find(team => team.acronym === match.makerOutcomeTeam)?.acronym ??
    ncaafData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    nbaData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    nhlData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    mlbData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    soccerData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    cbbData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    specialsData.find(team => team.name === match.makerOutcomeTeam)?.acronym ??
    match.makerOutcomeTeam;

  const awayAcronym =
    nflData.find(team => team.acronym === match.takerOutcomeTeam)?.acronym ??
    ncaafData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    nbaData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    nhlData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    mlbData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    soccerData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    cbbData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    specialsData.find(team => team.name === match.takerOutcomeTeam)?.acronym ??
    match.makerOutcomeTeam;

  if (match.makerOutcomePhrase.includes('FIGHT')) {
    match.makerOutcomeTeamColor = MMA_LEFT_COLOR;
    match.takerOutcomeTeamColor = MMA_RIGHT_COLOR;
    match.sport = 'MMA';
    let firstNameMaker;
    let lastNameMaker;
    if (match.makerOutcomeTeam.split(' ')[1]) {
      // eslint-disable-next-line no-unused-vars
      [firstNameMaker, lastNameMaker] = match.makerOutcomeTeam.split(' ');
      match.makerTeamAcronym = lastNameMaker;
    } else {
      match.makerTeamAcronym = homeAcronym;
    }
    let firstNameTaker;
    let lastNameTaker;
    if (match.takerOutcomeTeam.split(' ')[1]) {
      // eslint-disable-next-line no-unused-vars
      [firstNameTaker, lastNameTaker] = match.takerOutcomeTeam.split(' ');
      match.takerTeamAcronym = lastNameTaker;
    } else {
      match.takerTeamAcronym = awayAcronym;
    }
    return match;
  }

  match.makerOutcomeTeamColor = homeColor;
  match.takerOutcomeTeamColor = awayColor;
  match.makerTeamAcronym = homeAcronym;
  match.takerTeamAcronym = awayAcronym;
  match.sport = getSportLeague(match.makerOutcomeTeam);
  return match;
};

const transformGame = (game, sport) => {
  let home;
  let away;
  if (game.league.id === 1 && game.league.name !== 'mma') {
    // NFL & MLB both have a league id of 1 in the api
    home =
      nflData.find(team => team.name === game.teams.home.name) ??
      mlbData.find(team => team.name === game.teams.home.name);
    if (!home) {
      home = { color: DEFAULT_COLOR };
    }

    away =
      nflData.find(team => team.name === game.teams.away.name) ??
      mlbData.find(team => team.name === game.teams.away.name);
    if (!away) {
      away = { color: DEFAULT_COLOR };
    }
  } else if (game.league.id === 2) {
    // CFB & Champions League Soccer
    home =
      ncaafData.find(team => team.name === game.teams.home.name) ??
      soccerData.find(team => team.name === game.teams.home.name);
    away =
      ncaafData.find(team => team.name === game.teams.away.name) ??
      soccerData.find(team => team.name === game.teams.away.name);
    if (!home) {
      home = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }

    if (!away) {
      away = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
  } else if (game.league.id === 12) {
    home = nbaData.find(team => team.name === game.teams.home.name || team.acronym === game.teams.home.code);
    away = nbaData.find(team => team.name === game.teams.away.name || team.acronym === game.teams.away.code);
    // NBA
    if (!home) {
      home = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }

    if (!away) {
      away = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
  } else if (game.league.id === 57) {
    home = nhlData.find(team => team.name === game.teams.home.name || team.acronym === game.teams.home.code);
    away = nhlData.find(team => team.name === game.teams.away.name || team.acronym === game.teams.away.code);
    if (!home) {
      home = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
    if (!away) {
      away = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
  } else if (game.league.id === 116) {
    home = cbbData.find(team => team.name === game.teams.home.name || team.acronym === game.teams.home.code);
    away = cbbData.find(team => team.name === game.teams.away.name || team.acronym === game.teams.away.code);
    if (!home) {
      home = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
    if (!away) {
      away = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
  } else if (SOCCER_LEAGUES.includes(game.league.id)) {
    home = soccerData.find(team => team.name === game.teams.home.name || team.acronym === game.teams.home.code);
    away = soccerData.find(team => team.name === game.teams.away.name || team.acronym === game.teams.away.code);
    if (!home) {
      home = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
    if (!away) {
      away = { id: 'Unknown', acronym: 'Unknown', color: DEFAULT_COLOR };
    }
  } else if (game.league.name === 'mma') {
    // This block can be used to switch home and away
    // const [home_placeholder, away_placeholder] = [game.teams.home, game.teams.away];
    // game.teams.home = away_placeholder;
    // game.teams.away = home_placeholder;
    let firstName;
    let lastName;
    home = game.teams.home;
    if (home.name.split(' ')[1]) {
      // eslint-disable-next-line no-unused-vars
      [firstName, lastName] = home.name.split(' ');
      home.acronym = lastName;
    } else {
      home.acronym = home.name;
    }
    home.color = MMA_LEFT_COLOR;
    away = game.teams.away;
    if (away.name.split(' ')[1]) {
      // eslint-disable-next-line no-unused-vars
      [firstName, lastName] = away.name.split(' ');
      away.acronym = lastName;
    } else {
      away.acronym = away.name;
    }
    away.color = MMA_RIGHT_COLOR;
  } else {
    home = game.teams.home;
    away = game.teams.away;
  }
  game.teams.home.id = home.id || game.teams.home.id;
  game.teams.away.id = away.id || game.teams.away.id;
  game.teams.home.acronym = home.acronym;
  game.teams.away.acronym = away.acronym;
  game.teams.home.color = home.color;
  game.teams.away.color = away.color;
  game.teams.home.sport = sport;
  game.teams.away.sport = sport;
  return game;
};

const transformLiveBet = liveBet =>
  appendMatchColors({
    id: liveBet.id,
    sport: getSportLeague(liveBet.maker_outcome_team),
    makerOutcome: liveBet.maker_outcome,
    makerOutcomeTeam: liveBet.maker_outcome_team,
    makerName: liveBet.maker_name,
    takerOutcome: liveBet.taker_outcome,
    takerOutcomeTeam: liveBet.taker_outcome_team,
    takerName: liveBet.taker_name,
    makerOutcomePhrase: liveBet.maker_outcome_phrase,
    takerOutcomePhrase: liveBet.taker_outcome_phrase,
    restaurantId: liveBet.restaurant_id,
    restaurantName: liveBet.restaurant_name,
    restaurantItems: liveBet.restaurant_items,
    restaurantAddress: liveBet.maker_address,
    timestamp: liveBet.timestamp,
    accepted: liveBet.accepted,
  });

const transformFinishedBet = finishedBet =>
  appendMatchColors({
    id: finishedBet.id,
    result: finishedBet.result,
    makerName: finishedBet.maker_name,
    takerName: finishedBet.taker_name,
    makerOutcomePhrase: finishedBet.maker_bet,
    takerOutcomePhrase: finishedBet.taker_bet,
    restaurantId: finishedBet.restaurant_id,
    restaurantItems: finishedBet.restaurant_items,
    timestamp: finishedBet.timestamp,
    accepted: finishedBet.accepted,
  });

const transformCustomGame = (game, sport) => {
  const home = { id: `${game.id}_home`, acronym: `${game.teams.bet1_outcome1.name}`, color: CARJITSU_LEFT_COLOR };
  const away = { id: `${game.id}_away`, acronym: `${game.teams.bet1_outcome2.name}`, color: CARJITSU_RIGHT_COLOR };
  return {
    ...game,
    teams: {
      home: {
        id: home.id,
        acronym: home.acronym,
        color: home.color,
        sport,
      },
      away: {
        id: away.id,
        acronym: away.acronym,
        color: away.color,
        sport,
      },
    },
  };
};

async function getGames(bearerToken, sport, league = null) {
  const endpoint = sport === 'specials' ? 'games/custom' : `games/${encodeURIComponent(sport)}`;

  try {
    const response = await util.authorizedRequest(endpoint, {
      method: 'GET',
      bearerToken,
    });
    let games = response.response.map(game =>
      sport === 'specials' ? transformCustomGame(game, sport) : transformGame(game, sport),
    );
    if (league && sport === 'specials') {
      games = games.filter(game => game.league_name === league);
    }
    return { games };
  } catch (error) {
    console.error('Error fetching games:', error);
    return [];
  }
}

async function getSpecialsLeagues(bearerToken) {
  const endpoint = 'games/custom';
  try {
    const response = await util.authorizedRequest(endpoint, {
      method: 'GET',
      bearerToken,
    });
    const leagues = [...new Set(response.response.map(game => game.league_name))];
    return leagues;
  } catch (error) {
    console.error('Error fetching leagues:', error);
    return [];
  }
}

async function getOutcomes(bearerToken, sport, gameId) {
  const encodedSport = encodeURIComponent(sport);
  const response = await util.authorizedRequest(`outcomes/sport/${encodedSport}/game_id/${gameId}`, {
    method: 'GET',
    bearerToken,
  });

  // filter out redundant outcomes

  return response.filter(outcome => {
    if (outcome.type === 'lead' && ['q1', 'h1', 'h2', 'q4', 'game'].includes(outcome.scope)) {
      return false;
    }

    return true;
  });

  // return response;
}

/**
 *
 * @param {string} bearerToken
 * @param {Object} fight
 * @param {string} fight.makerOutcome
 * @param {string} fight.takerId
 * @param {number} fight.restaurantId
 * @param {Object[]} fight.items
 * @param {string} fight.payment
 * @param {string} fight.address
 * @returns
 */
async function submitFight(bearerToken, fight) {
  const fightData = {
    maker_id: 'tmp',
    maker_outcome: fight.makerOutcome,
    maker_payment_intent: fight.payment,
    maker_address: fight.address,
    taker_id: fight.takerId,
    taker_payment_intent: '',
    taker_address: '',
    restaurant_id: fight.restaurantId,
    restaurant_items: fight.items,
    sport: fight.sport,
    event: fight.event,
  };

  const response = await util.authorizedRequest(
    'livebets/create',
    {
      method: 'POST',
      bearerToken,
    },
    fightData,
  );

  return response;
}

async function acceptFight(bearerToken, betId, paymentId, restaurantAddress, taker_items) {
  const response = await util.authorizedRequest(
    `livebets/bets/accept/${betId}`,
    {
      method: 'POST',
      bearerToken,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {
      payment_id: paymentId,
      address: restaurantAddress,
      taker_items,
    },
  );
  return response;
}

async function rejectFight(bearerToken, betId) {
  const response = await util.authorizedRequest(`livebets/bets/reject/${betId}`, {
    method: 'POST',
    bearerToken,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
}

async function getLiveBets(bearerToken) {
  const response = await util.authorizedRequest('livebets/user/~', {
    method: 'GET',
    bearerToken,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.map(bet => transformLiveBet(bet));
}

async function acceptOpenLiveBet(bearerToken, betId, paymentId, address) {
  console.log(paymentId);
  console.log(address);
  try {
    const response = await util.authorizedRequest(
      `livebets/bets/open/accept/${betId}`,
      {
        method: 'PUT',
        bearerToken,
      },
      {
        payment_id: paymentId,
        address,
      },
    );
    return response;
  } catch (error) {
    console.error('Error accepting bet:', error);
    throw error;
  }
}

async function getOpenLiveBets(bearerToken) {
  const response = await util.authorizedRequest('/livebets/bets/open', {
    method: 'GET',
    bearerToken,
  });

  if (response && response.length > 0) {
    return response.map(bet => transformLiveBet(bet));
  }
  return [];
}

async function getFinishedBets(bearerToken) {
  const response = await util.authorizedRequest('finishedbets/user/~', {
    method: 'GET',
    bearerToken,
  });

  return response.map(bet => transformFinishedBet(bet));
}

async function getFinishedBetsPage(bearerToken, page, perPage) {
  const response = await util.authorizedRequest(
    `finishedbets/user/~?sort=TIMESTAMP&pg=${page}&per_pg=${perPage || 20}`,
    {
      method: 'GET',
      bearerToken,
    },
  );

  return response.map(bet => transformFinishedBet(bet));
}

async function createPayment(bearerToken, totalAmount) {
  const response = await util.authorizedRequest(
    'livebets/createpayment',
    {
      method: 'POST',
      bearerToken,
    },
    {
      amount: totalAmount,
    },
  );
  return response;
}

async function createTestPayment(bearerToken, totalAmount) {
  const response = await util.authorizedRequest(
    'livebets/createtestpayment',
    {
      method: 'POST',
      bearerToken,
    },
    {
      amount: totalAmount,
    },
  );
  return response;
}

async function getFriendsProfileHistory(bearerToken, user_id) {
  const response = await util.authorizedRequest(`finishedbets/user/${user_id}/bets`, {
    method: 'GET',
    bearerToken,
  });
  return response.map(bet => transformFinishedBet(bet));
}

async function getFriendsProfileHistoryPage(bearerToken, user_id, page, perPage) {
  const response = await util.authorizedRequest(
    `finishedbets/user/${user_id}/bets?sort=TIMESTAMP&pg=${page}&per_pg=${perPage || 20}`,
    {
      method: 'GET',
      bearerToken,
    },
  );
  return response.map(bet => transformFinishedBet(bet));
}

const exports = {
  getGames,
  getOutcomes,
  submitFight,
  getLiveBets,
  getOpenLiveBets,
  acceptOpenLiveBet,
  getFinishedBets,
  getFinishedBetsPage,
  acceptFight,
  rejectFight,
  createPayment,
  getFriendsProfileHistory,
  getFriendsProfileHistoryPage,
  getSpecialsLeagues,
};

export {
  getGames,
  getOutcomes,
  submitFight,
  getLiveBets,
  getOpenLiveBets,
  acceptOpenLiveBet,
  getFinishedBets,
  getFinishedBetsPage,
  acceptFight,
  rejectFight,
  createPayment,
  createTestPayment,
  getFriendsProfileHistory,
  getFriendsProfileHistoryPage,
  getSpecialsLeagues,
};
export default exports;
