import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonContent, IonList, isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
// import { checkboxOutline } from 'ionicons/icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { StartFightContext, ACTIONS } from '../StartFightContext';
import { AppContext } from '../../../global/AppContext';
import useCanGo from '../useCanGo';
import ModalHeader from '../ModalHeader';
import GamePanel from './GamePanel';
import ItemCard from '../items/ItemCard';
import nbaTeams from '../../../data/Sports/NBA.json';
import nflTeams from '../../../data/Sports/NFL.json';
import cfbTeams from '../../../data/Sports/NCAAF.json';
import nhlTeams from '../../../data/Sports/NHL.json';
import cbbTeams from '../../../data/Sports/CBB.json';
import mlbTeams from '../../../data/Sports/MLB.json';
import soccerTeams from '../../../data/Sports/SOCCER.json';
// import specialsTeams from '../../../data/Sports/SPECIALS.json';
import StripeForm from './StripeForm';

import '../../../styles/fight/summary/FightSummary.scss';
import img from '../../../assets/foodfight_under_review.png';
import { createPayment, createTestPayment } from '../../../global/request/bet';
import { AuthContext } from '../../authentication/AuthContext';
import useTeamsUIConfig from '../../../global/useTeamsUIConfig';
import AddressFields from '../../addressFields/AddressFields';
import { parseFormattedAddress } from '../../../global/util';
import { getPastAddresses } from '../../../global/request/user';

function FightSummary({ pageIndex }) {
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const startFightCtx = useContext(StartFightContext);
  const [clientSecret, setClientSecret] = useState('');
  const [showImage, setShowImage] = useState(false);
  const stripePromise =
    window.location.hostname === 'www.getfoodfight.link'
      ? loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY)
      : loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  // eslint-disable-next-line no-unused-vars
  const [reload, specialsTeams, loading, error] = useTeamsUIConfig();
  const contentRef = useRef();
  const [allTeams, setAllTeams] = useState([
    ...nflTeams,
    ...cfbTeams,
    ...nbaTeams,
    ...nhlTeams,
    ...mlbTeams,
    ...soccerTeams,
    ...cbbTeams,
  ]);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [address, setAddress] = useState(appCtx.restaurant.address);
  const [addressDetails, setAddressDetails] = useState({
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
  });
  const [pastAddresses, setPastAddresses] = useState([]);
  const [isAddressValid, setIsAddressValid] = useState(false);

  useEffect(() => {
    setAllTeams(prevTeams => [...prevTeams, ...specialsTeams]);
  }, [specialsTeams]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const changeCtxAddress = () => {
    if (!isAddressValid) {
      console.error('address in invalid format');
      return;
    }

    const trimmedApt = addressDetails.apt?.replace(' ', '');
    const formattedAddress = `${addressDetails.streetAddress}${trimmedApt ? ` ${trimmedApt}` : ''}, ${
      addressDetails.city
    }, ${addressDetails.state} ${addressDetails.zip}`;

    startFightCtx.dispatch({
      action: ACTIONS.SET_ADDRESS,
      payload: formattedAddress,
    });
    setAddress(formattedAddress);
  };

  const handleSetAddress = () => {
    changeCtxAddress();
    setShowAddressFields(false);
  };

  const sendPayment = async () => {
    // Create PaymentIntent as soon as the page loads
    if (appCtx.restaurant.id === 64 || appCtx.restaurant.id === 75) {
      let totalAmount = 0.0;
      startFightCtx.items.forEach(item => {
        totalAmount += item.price;
      });
      const payment =
        window.location.hostname === 'www.getfoodfight.link'
          ? await createPayment(authCtx.tokens.idToken, totalAmount)
          : await createTestPayment(authCtx.tokens.idToken, totalAmount);
      setClientSecret(payment);
    }
  };

  useEffect(() => {
    const fetchPastAddresses = async () => {
      setPastAddresses(await getPastAddresses(authCtx.tokens.idToken));
    };

    sendPayment();

    const addrDetails = parseFormattedAddress(address);
    if (!addrDetails.invalid) {
      setAddressDetails(addrDetails);
    }

    fetchPastAddresses();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (startFightCtx.submitted) {
      setShowImage(true);

      const timer = setTimeout(() => {
        setShowImage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [startFightCtx.submitted]);

  useCanGo(pageIndex, () => true && startFightCtx.items.length > 0, [startFightCtx.items]);

  const formatName = fullName => {
    const names = fullName?.trim()?.split(' ');
    if (names?.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}.`;
    }
    return fullName;
  };

  const findTeamAcronym = teamName => {
    const foundTeam = specialsTeams.find(team => team.name === teamName);
    const acronym = foundTeam ? foundTeam.acronym : teamName;
    /*
    const words = teamName.split(' ');
    const lastCharOfWord = words[words.length - 1].charAt(0).toUpperCase();

    if (lastCharOfWord === acronym.charAt(acronym.length - 1)) {
      return `${acronym.slice(0, -1)} ${words[words.length - 1]}`;
    }
    
    return `${acronym} ${words[words.length - 1]}`; */

    return acronym;
  };

  const findMakerTeam = () => {
    const getOutcomeTeam = startFightCtx.outcome.name;
    if (startFightCtx.game.sport === 'Specials') {
      if (getOutcomeTeam === startFightCtx.game.teams.home.acronym) {
        return startFightCtx.game.teams.home.acronym;
      }
    }
    if (getOutcomeTeam === startFightCtx.game.teams.home.name) {
      return startFightCtx.game.teams.home.acronym;
    }
    return startFightCtx.game.teams.away.acronym;
  };

  const findTakerTeam = () => {
    const getOutcomeTeam = startFightCtx.outcome.name;
    if (startFightCtx.game.sport === 'Specials') {
      if (getOutcomeTeam === startFightCtx.game.teams.home.acronym) {
        return startFightCtx.game.teams.away.acronym;
      }
    }
    if (getOutcomeTeam === startFightCtx.game.teams.home.name) {
      return startFightCtx.game.teams.away.acronym;
    }
    return startFightCtx.game.teams.home.acronym;
  };

  const findTeamColor = teamName => {
    const DEFAULT_COLOR = '#0000ff';
    let foundTeam = allTeams.find(team => team.name === teamName);
    if (foundTeam === undefined) {
      const teamNameAcr = findTeamAcronym(teamName);
      foundTeam = allTeams.find(team => team.acronym === teamNameAcr);
      // for specials
      if (foundTeam === undefined) {
        foundTeam = specialsTeams.find(team => team.acronym === teamNameAcr);
      }
    }
    const color = foundTeam ? foundTeam.color : DEFAULT_COLOR;
    return color;
  };

  const findMakerColor = () => {
    const getOutcomeTeam = startFightCtx.outcome.name;
    if (startFightCtx.game.sport === 'Specials') {
      if (getOutcomeTeam === startFightCtx.game.teams.home.acronym) {
        return findTeamColor(startFightCtx.game.teams.home.acronym);
      }
      return findTeamColor(startFightCtx.game.teams.away.acronym);
    }
    if (getOutcomeTeam === startFightCtx.game.teams.home.name) {
      return startFightCtx.game.teams.home.color;
    }
    return startFightCtx.game.teams.away.color;
  };

  const findTakerColor = () => {
    const getOutcomeTeam = startFightCtx.outcome.name;
    if (startFightCtx.game.sport === 'Specials') {
      if (startFightCtx.game.sport === 'Specials') {
        if (getOutcomeTeam === startFightCtx.game.teams.home.acronym) {
          return findTeamColor(startFightCtx.game.teams.away.acronym);
        }
        return findTeamColor(startFightCtx.game.teams.home.acronym);
      }
    }
    if (getOutcomeTeam === startFightCtx.game.teams.home.name) {
      return startFightCtx.game.teams.away.color;
    }
    return startFightCtx.game.teams.home.color;
  };

  useEffect(() => {
    const tryAddKeyboardListener = async () => {
      try {
        await Keyboard.addListener('keyboardDidShow', info => {
          contentRef.current?.scrollByPoint(0, info.keyboardHeight, 0);
        });
      } catch (err) {
        console.error(err);
      }
    };

    if (isPlatform('ios')) {
      tryAddKeyboardListener();
    }

    return () => {
      const tryRemoveKeyboardListeners = async () => {
        try {
          await Keyboard.removeAllListeners();
        } catch (err) {
          console.error(err);
        }
      };

      if (isPlatform('ios')) {
        tryRemoveKeyboardListeners();
      }
    };
  }, [contentRef.current]);

  return (
    <>
      <ModalHeader pageName="Fight Summary" />
      <IonContent ref={contentRef} id="fight-summary-modal-page" className="ion-padding">
        <div id="game-section">
          <h1 className="section-header section-font">Matchup</h1>
          <GamePanel
            classname="game-data"
            gameData={{
              // TODO: validate split array length
              sport:
                startFightCtx.game.sport === 'Specials'
                  ? startFightCtx.game.league_name
                  : startFightCtx.game.league.name,
              makerTeam: findMakerTeam(),
              makerName: 'YOU',
              takerName: formatName(startFightCtx?.challenger?.name),
              takerTeam: findTakerTeam(),
            }}
            leftColor={findMakerColor()}
            rightColor={findTakerColor()}
          />
        </div>
        <div id="outcome-section">
          <h1 className="section-header section-font">Your Outcome</h1>
          <div id="outcome-info" className="outcome-fight-summary">
            {startFightCtx.game.sport === 'Specials'
              ? startFightCtx.outcome.name
              : `${findTeamAcronym(startFightCtx.outcome.name)}
            ${startFightCtx.outcome.type}
            ${startFightCtx.outcome.scope}`}
          </div>
        </div>
        <div>
          <div id="items-section">
            <h1 className="section-header section-font">Fight Cart</h1>
            <IonList>
              {startFightCtx.items.map((item, index) => {
                return (
                  <ItemCard
                    item={{
                      id: item.id,
                      name: item.name,
                      price: item.price,
                      amount: item.amount,
                    }}
                    showTotal
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                );
              })}
            </IonList>
          </div>
          {appCtx.restaurant.id === 64 || appCtx.restaurant.id === 44 || appCtx.restaurant.id === 75 ? (
            <>
              <div>
                <h1 className="section-header section-font">Payment Information</h1>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <StripeForm />
                  </Elements>
                )}
                <p className="disclaimer-text">
                  By proceeding you authorize a 48 hr hold on your card for the amount listed in the Fight Cart. Win =
                  authorization canceled; Lose = authorization processed.
                </p>
              </div>
              {appCtx.restaurant.id !== 75 && (
                <div className="address-section">
                  <h1 className="section-header section-font">Address</h1>
                  <p className="address-text">
                    {address}
                    {!showAddressFields && (
                      <button type="button" onClick={() => setShowAddressFields(true)} className="change-text">
                        Change Address
                      </button>
                    )}
                  </p>
                  {showAddressFields && (
                    <>
                      <AddressFields
                        addressDetails={addressDetails}
                        setAddressDetails={setAddressDetails}
                        setIsFormValid={setIsAddressValid}
                        showAutocompleteDefault={false}
                        pastAddresses={pastAddresses}
                      />
                      <IonButton expand="block" onClick={handleSetAddress} className="set-address-button">
                        Set Address
                      </IonButton>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <div />
          )}
        </div>
      </IonContent>
      {showImage && (
        <div id="fight-under-review">
          <img src={img} alt="FoodFight under review" />
        </div>
      )}
    </>
  );
}

export default FightSummary;
